<template>
    <span />
</template>
<script>
export default {
    name: 'NotFound',
    created: function () {
        this.$router.push({ path: '/' })
    },
}
</script>
